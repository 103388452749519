.value {
    text-align: right;
    font-weight: bold;
}
.label {
    font-size: small;
    text-align: right;
    padding-top: 1.5rem;
}
.icon {
    /* border-radius: 3px; */
    /* background-color: #999; */
    padding: 20px;
    margin-top: -20px;
    margin-right: 20px;

    color: white;
    float: left;
}

.cardHeight {
    height: 150px;
}

.bgGreen {
    background-color: #2cb34a;
}
.bgOrange {
    background-color: #ee8225;
}
.bgBlue {
    background-color: #2f3ae9;
}
.bgRed {
    background-color: #d91616;
}
