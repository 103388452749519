.dma-name-abbr {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b8b7bd;
}

.c-gap-30 {
    column-gap: 30px;
}
