.custom-input {
    border: 1px solid #dbdbdb;
    border-radius: 7px;
    padding: 15px;
    width: 100%;
    color: #000000;
}

.custom-input:focus .custom-input:hover {
    outline: none;
}

.auth-label {
    text-align: left;
    letter-spacing: 0.35px;
    color: #000000;
    opacity: 1;
    font-weight: bold;
    font-family: Avenir-bold;
    text-transform: uppercase;
    font-size: 13px;
}

.styled-select select {
    color: #94979b 0% 0% no-repeat padding-box;
    opacity: 1;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.p-0-20 {
    padding: 0 20px;
}
