.otp-input {
    height: 50px;
    width: 50px;
    /* border-radius: 50%; */
    border-style: none;
    border-bottom: #d8d8d8 solid 1px;
    text-align: center;
    outline: none !important;
    font-size: 20px;
    margin: 0 15px;
}
