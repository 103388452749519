.drag-zone {
    color: #717171;
    border: 1px #717171 dashed;
    border-radius: 7.5px;
}

.uploadIcon {
    font-size: 40px;
    color: black;
}
