.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 7px;
    transition: all 0.3s;
    height: 50px;
    outline: none;
}

.custom-select {
    /* border: 1px solid #dbdbdb; */
    border-radius: 7px;
    width: 100%;
    color: #000000;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 49px;
    transition: all 0.3s;
}
