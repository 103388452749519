/* .dma-header th{
    color: #ffffff;
    background-color: #39b54a;
    height: 70px
} */

.ant-table-thead > tr > th {
    color: #ffffff;
    background-color: #39b54a;
    height: 50px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #39b54a;
    border-radius: 2px;
    transition: all 0.3s;
    height: 40px;
}
.mr-15 {
    margin-right: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mr-5 {
    margin-right: 15px;
}

.ml-5 {
    margin-left: 15px;
}
.ant-select-selection-item {
    line-height: 40px;
}

.overflow-scroll {
    overflow: scroll;
}

.products-heading {
    margin-top: 40px;
    margin-bottom: 25px;
    color: #2cb34a;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
}

.new-product-form-label {
    font-weight: bolder;
    text-transform: uppercase;
}

.new-product-form-group {
    margin-top: 45px;
}

.add-product-btn {
    margin-left: 20px;
}

.btn {
    float: right;
    width: 194px;
    height: 52px;
    margin: 135px 7px 0 46.1px;
    padding: 16px 46px 16px 45px;
    border: 3px rgba(120, 137, 149, 0.26);
    box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.26);
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.new-product-action-buttons-cancel {
    background-color: #94979b;
}

.new-product-action-buttons-create {
    background-color: #2cb34a;
}
/* .invalid-feedback{display: block !important;} */
.pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 60.6px;
}

.pagination-wrapper .ant-pagination {
    display: block !important;
}
