.dma-header {
    color: #ffffff;
    font-weight: bold;  
}

@media print {
    .do-not-print {
        display: none;
    }
}

.pagination-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 40px 0;
}

.ant-table-thead > tr > th {
    color: #ffffff;
    background-color: #39b54a;
    height: 50px;
}

.primary-bg-color {
    background-color: #39b54a;
}

.white-color {
    color: #ffffff;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #39b54a;
    border-radius: 2px;
    transition: all 0.3s;
    height: 40px;
}
.mr-15 {
    margin-right: 15px;
}

.ant-select-selection-item {
    line-height: 40px;
}

.overflow-scroll {
    overflow: scroll;
}


.ant-select-selection-item {
    top: -5px;
}

.ant-select-arrow {
    margin-top: 0 !important;
}

.dma-search-input.input-search {
    line-height: 49px !important;
}


.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
     height: 50px !important;
 }