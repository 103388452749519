.side-nav-item {
    font-size: 16px;
    font-weight: 600;
    color: #94979b !important;
}

.disabledSideNav {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
}

.side-nav-item-link {
    color: black;
    display: block;
    padding-left: 1.8rem;
    text-decoration: none;
}
.side-nav-item-link:hover {
    color: black;
}

/* .sideNav-title {
    color: white;
} */

.side-nav-item-link {
    color: black;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.side-nav-item-link.selected {
    background-color: #39b54a;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.side-nav-item-link.selected > .side-nav-item > .sideNav-title {
    color: white !important;
}

.side-nav-item-link.selected > .side-nav-item > svg {
    color: white !important;
}

.side-nav-style {
    padding: 0 0 421px;
    background-color: #ffffff;
    box-shadow: 1px 0 10px 0 rgba(0, 0, 0, 0.03), 0 2px 6px 0 rgba(0, 0, 0, 0.5);
}

.border-bottom {
    border-bottom: #9da199;
}
