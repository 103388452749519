.v-stretch {
    min-height: 90% !important;
}

.tab-pane.active {
    background-color: transparent !important;
}

.elevated-card {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
    border: none !important;
}

.pr-1 {
    padding-right: 10px !important;
}

.pl-1 {
    padding-left: 10px !important;
}

body {
    overflow-x: hidden;
    background-color: #f3f5fa;
}

.no-wrap {
    white-space: nowrap;
}

.text-right {
    text-align: right !important;
}

.fw-bold {
    font-weight: bold !important;
}

.fw-bolder {
    font-weight: bolder !important;
}

.font-small {
    font-size: 14px;
}

.font-medium {
    font-size: 20px;
}

.font-large {
    font-size: 25px !important;
}

.height-100 {
    height: 100%;
}

.page-bg {
    background-color: #f3f5fa !important;
    padding-left: 250px !important;
    padding-top: 60px !important;
    -moz-transition: all 0.25s ease;
    -o-transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
}

.ant-table-wrapper .ant-spin-container {
    border-radius: 10px !important;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
    background-color: white;
}
.ant-table-wrapper .ant-spin-container .ant-table-cell {
    text-align: center;
    font-size: 14px;
}
.ant-table-wrapper .ant-spin-container .ant-table-row:nth-child(odd) {
    background-color: #f3f5fa;
}
.ant-table-wrapper .ant-spin-container .ant-table-thead tr th:first-child {
    border-radius: 10px 0 0 0;
}
.ant-table-wrapper .ant-spin-container .ant-table-thead tr th:last-child {
    border-radius: 0 10px 0 0;
}

.PENDING {
    background-color: rgba(238, 137, 36, 0.25) !important;
    color: #ee8924 !important;
    white-space: nowrap;
}

.rdt_TableHeadRow div div:first-child {
    border-radius: 10px 0 0 0;
}

.REJECTED {
    background-color: rgba(240, 28, 29, 0.25) !important;
    color: #f01c1d !important;
    white-space: nowrap;
}

.APPROVED {
    background-color: rgba(59, 184, 77, 0.25) !important;
    color: #3bb84d !important;
    white-space: nowrap;
}

.ant-pagination {
    padding: 15px;
}

.ant-picker {
    display: block !important;
    width: 100% !important;
    height: 40px;
    text-align: center;
    padding: 0;
}
.ant-picker .ant-picker-input {
    width: 100%;
    height: 100%;
    padding: 5px 10px;
}
.ant-picker .ant-picker-input input {
    text-align: center;
}

.ant-picker.ant-picker-focused {
    background-color: red;
}

.ant-select {
    width: 100% !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px !important;
}
.ant-select .ant-select-selector {
    height: 100%;
    padding-top: 5px !important;
}

.input-search {
    background-color: #eee !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
}

.ant-card {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
}

.form-label {
    font-weight: bold !important;
    font-size: 12px !important;
}

.modal-content {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.35);
}

@media only screen and (max-width: 768px) {
    .page-bg {
        padding-left: 0 !important;
        padding-top: 50px;
    }

    .navbar.enhanced {
        left: 0;
    }

    .toggle-sidebar {
        display: flex;
    }

    .header-search {
        display: none !important;
    }

    .font-small {
        font-size: 12px;
    }

    .font-medium {
        font-size: 14px;
    }

    .font-large {
        font-size: -6px !important;
    }

    .side-nav {
        margin-left: -300px !important;
    }
    .side-nav .side-nav-item {
        padding-left: 10px !important;
    }
}

/*# sourceMappingURL=enhancements.css.map */
