.id-product-info .status {
    width: 100px;
    font-family: AvenirNext;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: var(--white-fill);
    border-radius: 5px;
    background-color: #2cb34a;
    padding: 6px 10px;
    color: #fff;
}

.manufacturerName {
    opacity: 0.6;
    font-family: AvenirNext;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #525252;
}

.status-approval {
    cursor: pointer;
    font-size: 15px;
}

.site-card-border-less-wrapper,
.ant-btn-group.ant-dropdown-button {
    display: flex;
    flex-direction: row-reverse;
}

.id-product-info .title {
    font-family: AvenirNext;
    font-size: 30px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: #2cb34a;
}

.id-product-info .description {
    font-family: AvenirNext;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 29px;
    letter-spacing: normal;
    color: #525252;
    margin-top: 60px;
}

.id-product-info tr {
    height: 30px;
    margin: 0 101px 0 0;
    font-family: AvenirNext;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: var(--colors-black-100);
}

.id-product-info tr td:first-child {
    font-weight: 800;
    color: #000;
}

.id-product-info tr td:last-child {
    font-family: AvenirNext;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: justify;
    color: #666666;
}

.id-product-info .price {
    margin: 8px 177px 271px 43px;
    font-family: AvenirNext;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.37;
    letter-spacing: normal;
    color: #2cb34a;
    margin-left: 0.3rem;
}
