/* .dma-header th{
    color: #ffffff;
    background-color: #39b54a;
    height: 70px
} */

.ant-table-thead > tr > th {
    color: #ffffff;
    background-color: #39b54a;
    height: 50px;
}

.recharts-legend-wrapper {
    position: absolute;
    width: 450px;
    height: auto;
    left: 20px;
    bottom: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #39b54a;
    border-radius: 2px;
    transition: all 0.3s;
    height: 40px;
}

.active {
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
    color: #ffffff;
    background-color: #2cb34a;
}

.inactive {
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
    color: #ffffff;
    background-color: #f01d1d;
}

.mr-15 {
    margin-right: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mr-5 {
    margin-right: 15px;
}

.ml-5 {
    margin-left: 15px;
}
.ant-select-selection-item {
    line-height: 40px;
}

.overflow-scroll {
    overflow: scroll;
}

.profile-initials-manufacturers {
    font-size: 30px;
    background-color: #b9babf;
    border-radius: 100%;
    color: #cccccc;
    text-transform: uppercase;
    text-align: center;
    height: 50px;
    width: 50px;
}
