.side-nav {
    min-height: 100%;
    position: fixed;
    width: 20% !important;
}

.side-nav-header {
    height: 5rem;
    min-width: 100%;
    margin-bottom: 1.5rem;
}

.side-nav-header img {
    object-fit: contain;
    height: 80%;
}

.sideNav-title {
    color: #94979b !important;

}

.sideNav-title:active {
    color: white !important;
}
