.ant-table-thead > tr > th {
    color: #ffffff;
    background-color: #39b54a;
    height: 50px;
}

td:nth-of-type(5) {
    text-align: left !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #39b54a;
    border-radius: 2px;
    transition: all 0.3s;
    height: 40px;
}
.mr-15 {
    margin-right: 15px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mr-5 {
    margin-right: 15px;
}

.ml-5 {
    margin-left: 15px;
}
.ant-select-selection-item {
    line-height: 40px;
}

.overflow-scroll {
    overflow: scroll;
}

.audit-heading {
    margin-top: 40px;
    margin-bottom: 25px;
    color: #2cb34a;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
}

.new-audit-form-label {
    font-weight: bolder;
    text-transform: uppercase;
}

.new-audit-form-group {
    margin-top: 45px;
}

.unset-lh {
    line-height: unset !important;
}

.unset-p {
    padding: unset !important;
}

.br-5 {
    border-radius: 5px;
}

.audit-filter-input {
    padding: 6px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
}

.btn {
    float: right;
    width: 194px;
    height: 52px;
    margin: 135px 7px 0 46.1px;
    padding: 16px 46px 16px 45px;
    border: 3px rgba(120, 137, 149, 0.26);
    box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.26);
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}