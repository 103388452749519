.auth-header {
    text-transform: capitalize;
    color: #000000;
    font-family: Avenir-bold;
}

.auth-subheader {
    color: #797a7d;
}

.padding-20percent {
    padding: 72px 20%;
}

.personal-bg-img {
    background-image: url('../../assets/auth.jpg');
}

.login-bg-img {
    background-image: url('../../assets/auth3.jpg');
}

.kyc-bg-img {
    background-image: url('../../assets/auth1.jpg');
}

.img-overlay {
    background-image: url('../../assets/Overlay.png');
    background-size: cover;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .m-display-none {
        display: none;
    }

    .m-display {
        display: block;
    }
}

@media only screen and (max-width: 912px) {
    .otp-resend-container {
        display: flex;
        flex-wrap: wrap;
    }

    .otp-input {
        margin: 0 6px !important;
        width: 32px !important;
    }

}

@media only screen and (max-width: 300px) {
  
    .otp-input {
        margin: 0 5px !important;
        width: 24px !important;
    }
}
