.users-heading {
    margin-top: 40px;
    margin-bottom: 25px;
    color: #2cb34a;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
}

.ant-pagination {
    display: none;
}

.users-card-summary {
    height: 80px;
    min-height: auto;
}

.all-users-table {
    margin-top: 3%;
}

/* .add-user-btn {
    color: #2cb34a;
    font-weight: bolder;
}

.add-user-btn:hover {
    color: white !important;
} */

.add-user {
    color: #2cb34a;
    font-weight: bolder;
}

.ml-3 {
    margin-left: 0.75rem;
}

.all-users-tabs {
    margin-top: 20px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #2cb34a;
}

.users-summary-cardbody {
    height: 260px !important;
    min-height: auto;
}

.user-margin-right {
    margin-right: 1.5rem;
}

.user-details-name {
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.user-details-number {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.03px;
    color: #9fa1a3;
}

.user-name-abbr {
    width: 48px;
    height: 48px;
    /* margin: 0 21px 0 0; */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b8b7bd;
}

.ant-breadcrumb > span:last-child {
    color: #39b54a;
}

.user-permission {
    min-height: 468px;
    padding: 23px;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23), 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: white;
    margin-bottom: 62px;
}

.user__role {
    font-size: 15px;
    font-weight: 500;
    color: #949790;
}

.text-align-left {
    text-align: left;
}

.mb-13 {
    margin-bottom: 13px;
}

.users-summary-cardParagraph {
    margin: 0 186px 14px 0;
    object-fit: contain;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: 0.7px;
    color: #94979b;
}
