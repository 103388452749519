.manufacturer {
    .nav-tabs .nav-item.show .nav-link,
    .nav-tabs .nav-link.active {
        color: #39b54a;
        background-color: #f9fafc;
        border: none;
        border-bottom: #39b54a 2px solid;
        font-weight: bold;
    }

    .nav-item.show:hover {
        border: none;
        color: #738675;
        opacity: 0.5;
    }

    .nav-tabs .nav-link {
        border: none;
        color: #000000;
        padding: 20px 40px;
    }

    .auth-header {
        text-transform: capitalize;
        color: #000000;
    }
    .auth-subheader {
        color: #797a7d;
    }
    .padding-20percent {
        padding: 20%;
    }
    .personal-bg-img {
        background-image: url('../../assets/auth.jpg');
    }
    .login-bg-img {
        background-image: url('../../assets/auth3.jpg');
    }
    .kyc-bg-img {
        background-image: url('../../assets/auth1.jpg');
    }
    .img-overlay {
        background-image: url('../../assets/Overlay.png');
        background-size: cover;
        width: 100%;
        height: 100%;
    }
}
