.demand {
    .active {
        background-color: #fbfbfa !important;
        color: black;
    }
}

.farmerRequestDate {
    margin-right: 18px;
    height: 48px;
}
