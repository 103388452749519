.primary-btn {
    background: #39b54a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    border: none;
    height: 48px;
    line-height: 48px;
}
