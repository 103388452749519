.categories-select {
    max-width: 25%;
    margin-bottom: 30px;
}

.categories-heading {
    margin-top: 40px;
    margin-bottom: 25px;
    color: #2cb34a;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
}

#add-category:hover {
    color: white !important;
}

.width-200 {
    width: 200px;
}

.col-form-label {
    color: #949790;
}

.btn {
    float: right;
    width: 194px;
    height: 52px;
    margin: 135px 7px 0 46.1px;
    padding: 16px 46px 16px 45px;
    border: 3px rgba(120, 137, 149, 0.26);
    box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.26);
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
}

.categories-action-buttons-cancel {
    background-color: #94979b;
}

.categories-action-buttons-save {
    background-color: #2cb34a;
}

.form-check {
    margin-bottom: 30px;
    color: #949790;
}
