$saf-primary: #2cb34a;
$primary: $saf-primary !default;
@import '~bootstrap/scss/bootstrap';
@font-face {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/AvenirNextLTPro-Regular.otf'); /* IE9 Compat Modes */
    src: local('Avenir'), local('Avenir'),
        /* Modern Browsers */ url('./fonts/AvenirNextLTPro-Regular.otf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/AvenirNextLTPro-Regular.otf') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Avenir-bold';
    font-style: normal;
    font-weight: normal;
    src: url('./fonts/AvenirNextLTPro-Bold.otf'); /* IE9 Compat Modes */
    src: local('Avenir'), local('Avenir'),
        /* Modern Browsers */ url('./fonts/AvenirNextLTPro-Bold.otf') format('truetype'),
        /* Safari, Android, iOS */ url('./fonts/AvenirNextLTPro-Bold.otf') format('svg'); /* Legacy iOS */
}

html {
    height: 100%;
    margin: 0;
    font-family: Avenir;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    font-family: Avenir, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    background-color: #f8fafb !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fs-24 {
    font-size: 24px;
}
.fs-20 {
    font-size: 20px;
}

.fs-12 {
    font-size: 12px;
}

.fs-16 {
    font-size: 16px;
}

.fw-500 {
    font-weight: 500;
    font-family: Avenir-bold;
}

.fw-700 {
    font-weight: 700;
    font-family: Avenir-bold;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.text-align-left {
    text-align: left;
}

.primary-color {
    color: #2cb34a;
}

.pointer {
    cursor: pointer;
}

.grey-color {
    color: #0000009a;
}

.white-color {
    color: #ffffff;
}

.black-color {
    color: #000000;
}

.fullheight {
    height: 100%;
}

.register-big-header {
    font-size: 36px;
    font-weight: 700;
}
/* 
.card {
  box-shadow: 0px 1px 4px #000[00033;
  border-radius: 8px;
  background-color: #ffffff;
  min-height: 600px;
  padding: 30px;
} */

.height-150 {
    height: 150px;
}

.height-100vh {
    height: 100vh;
}

.margin-auto {
    margin: auto;
}

.red-color {
    color: red;
}

.uppercase {
    text-transform: uppercase;
}

.layout-bg {
    background-color: #f9fafc;
    min-height: 100vh;
}

.primary-btn {
    background: #2cb34a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    font-family: Avenir-bold;
    border: none;
    height: 48px;
    line-height: 48px;
}

.primary-btn-2 {
    background: inherit;
    border: #2cb34a solid 1px;
    border-radius: 5px;
    opacity: 1;
    color: #2cb34a;
    text-align: center;
    height: 48px;
    line-height: 48px;
}

.primary-btn-2:hover {
    background: #2cb34a 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffffff;
    text-align: center;
}

.delete-btn {
    background: rgb(221, 59, 59) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    border: none;
    height: 48px;
    line-height: 48px;
}

.delete-btn-2 {
    background: inherit;
    border: rgb(221, 59, 59) solid 1px;
    border-radius: 5px;
    opacity: 1;
    color: rgb(221, 59, 59);
    text-align: center;
    height: 48px;
    line-height: 48px;
}

.delete-btn-2:hover {
    background: rgb(221, 59, 59) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffffff;
    text-align: center;
}

.btn-width {
    width: 150px;
}

.cancel-btn {
    background: #96979b 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffffff;
    text-align: center;
    border: none;
    height: 48px;
    line-height: 48px;
}

.input-search {
    background-color: #f7f7f7;
    border-radius: 8px;
    border: none;
    //height: 40px;
    outline: none;
    line-height: 40px;
}

.icon-padding {
    line-height: 40px !important;
    width: 40px;
    color: #d7d7d7;
}

.logout:hover {
    color: #2cb34a;
}

.pull-right {
    float: right;
}

.ant-breadcrumb > span:last-child a {
    color: #2cb34a !important;
}

.ant-breadcrumb a:hover {
    color: #222222;
}

.pending {
    background-color: #f3c1a6;
    color: #ea754b;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
}

.notuploaded {
    background-color: rgba(17, 95, 239, 0.4);
    color: white;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
}

.rejected,
.Rejected {
    background-color: red;
    color: white;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
}

.Approved,
.approved {
    background-color: #2cb34a;
    color: white;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
}

.disabled-status {
    background-color: #dddddd;
    color: #666665;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
}

.pending-color {
    color: #ea754b;
}

.active-status {
    background-color: #c1e9c6;
    color: #2cb34a;
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
}

.page-title-holder {
    min-height: 100px;
    align-items: center;
}

.pull-right {
    text-align: right;
}

.pull-left {
    text-align: left;
}

.border-btn {
    border: 1px solid #2cb34a;
    color: #000000;
    /* background-color: #ffffff; */
    padding: 5px 15px;
    border-radius: 4px;
    cursor: pointer;
    min-width: 88px;
}

.border-btn:hover {
    border: 1px solid #2cb34a;
    color: #ffffff;
    background-color: #2cb34a;
}

.pointer {
    cursor: pointer;
}

.pointer-emphasized {
    cursor: pointer !important;
}

.profile-initials {
    font-size: 70px;

    border-radius: 100%;

    text-transform: uppercase;
    text-align: center;
    height: 150px;
    width: 150px;
}

.light-grey-color {
    color: #cccccc;
}

.grey-bg-color {
    background-color: #b9babf;
}

.fullwidth {
    width: 100%;
}

.profile-header {
    text-transform: uppercase;
    color: #b9babf;
}

.ant-breadcrumb {
    margin-top: 8px !important;
}

.sales-card {
    height: 320px;
    display: flex;
    flex-direction: column-reverse;
}

.dma-sales-card {
    height: 450px;
}

.sales-orders-card {
    height: 320px;
}

.w-fit-content {
    width: fit-content;
}

.reduce-opacity:hover {
    opacity: 0.5;
}

.line-break-anywhere {
    line-break: anywhere;
}

.min-height-100vh {
    min-height: 100vh;
}

.card-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.user-card-height {
    min-height: 634px;
}

.m-display {
    display: none;
}

.uppercase {
    text-transform: uppercase;
}

.user__label__status {
    padding: 4px 13px;
    opacity: 0.8;
    border-radius: 4px;
    background-color: rgba(59, 184, 77, 0.4);
    color: #3bb84d;
    width: fit-content;
}

.user__label__Active {
    padding: 4px 13px;
    border-radius: 4px;
    background-color: green;
    color: white;
    width: fit-content;
}

.user__label__Inactive {
    padding: 4px 13px;
    border-radius: 4px;
    background-color: red;
    color: white;
    width: fit-content;
}

.user__permissions__holder {
    font-size: 18px;
    color: #2cb34a;
}

.user__status__padding {
    padding: 4px 13px;
}

.fit__content {
    width: fit-content;
}

.card__base {
    min-height: 210px;
    border-radius: 1px;
    border: solid 1px rgba(46, 91, 255, 0.08);
    background-color: var(--white-ffffff-100);
    box-shadow: 0 10px 20px 0 rgba(46, 91, 255, 0.07);
    padding: 26px;
}

.user__label {
    color: #9fa1a3;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.13px;
}

.user__label__value {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.38;
    letter-spacing: normal;
    color: #2e384d;
}
@media only screen and (max-width: 768px) {
    .side-nav {
        display: none;
    }

    .innerContent-main {
        min-width: 100% !important;
    }

    .innerContent {
        margin: 0;
        width: 100%;
    }

    .card {
        margin-bottom: 8px;
        margin-top: 8px;
    }
}

body {
    min-height: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

#sidebar-wrapper {
    min-height: 100%;
    border-right: 1px solid #dee2e6 !important;
    //margin-left: -15rem;
    -webkit-transition: margin 0.25s ease-out;
    -moz-transition: margin 0.25s ease-out;
    -o-transition: margin 0.25s ease-out;
    transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}

@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

//new navbar css
.wrapper,
body,
html {
    min-height: 100%;
}

@media (max-width: 991.98px) {
    body:not(.sidebar-mini-md) .content-wrapper,
    body:not(.sidebar-mini-md) .content-wrapper::before,
    body:not(.sidebar-mini-md) .main-footer,
    body:not(.sidebar-mini-md) .main-footer::before,
    body:not(.sidebar-mini-md) .main-header,
    body:not(.sidebar-mini-md) .main-header::before {
        margin-left: 0;
    }
}

@media (min-width: 768px) {
    body:not(.sidebar-mini-md) .content-wrapper,
    body:not(.sidebar-mini-md) .main-footer,
    body:not(.sidebar-mini-md) .main-header {
        transition: margin-left 0.3s ease-in-out;
        margin-left: 300px;
    }
}
.navbar-white {
    background-color: #fff;
}
.main-header {
    border-bottom: 1px solid #dee2e6;
    z-index: 1034;
}
.navbar-expand {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 0.5rem;
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.main-header .navbar-nav .nav-item {
    margin: 0;
}
.d-none {
    display: none !important;
}

@media (min-width: 576px) {
    .d-sm-inline-block {
        display: inline-block !important;
    }
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
}

.main-header .nav-link {
    height: 2.5rem;
    position: relative;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
}

.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (max-width: 991.98px) {
    .sidebar-mini.sidebar-collapse .main-sidebar {
        box-shadow: none !important;
    }
}
.layout-fixed .main-sidebar {
    bottom: 0;
    float: none;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
}
:not(.layout-fixed) .main-sidebar {
    height: inherit;
    min-height: 100%;
    position: fixed;
    top: 0;
}
.sidebar-collapse .main-sidebar,
.sidebar-collapse .main-sidebar::before {
    margin-left: -360px;
}
.elevation-4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}
[class*='sidebar-dark-'] {
    background-color: #343a40;
}
.main-sidebar {
    height: 100vh;
    overflow-y: hidden;
    z-index: 1038;
}
@media (max-width: 767.98px) {
    .main-sidebar,
    .main-sidebar::before {
        box-shadow: none !important;
        margin-left: -300px;
    }
}
.main-sidebar,
.main-sidebar::before {
    transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
    width: 300px;
}

[class*='sidebar-dark'] .brand-link {
    border-bottom: 1px solid #4b545c;
    color: rgba(255, 255, 255, 0.8);
}
.layout-fixed .brand-link {
    width: 300px;
}
.brand-link {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: 0.8125rem 0.5rem;
    transition: width 0.3s ease-in-out;
    white-space: nowrap;
}

.brand-link .brand-image {
    float: left;
    line-height: 0.8;
    margin-left: 0.8rem;
    margin-right: 0.5rem;
    margin-top: -3px;
    max-height: 33px;
    width: auto;
}
.elevation-3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
}
.img-circle {
    border-radius: 50%;
}

.main-sidebar .brand-text,
.main-sidebar .logo-xl,
.main-sidebar .logo-xs,
.sidebar .nav-link p,
.sidebar .user-panel .info {
    transition: margin-left 0.3s linear, opacity 0.3s ease, visibility 0.3s ease;
}
.font-weight-light {
    font-weight: 300 !important;
}

.layout-fixed .wrapper .sidebar {
    height: calc(100vh - (3.5rem + 1px));
}
.os-host-overflow {
    overflow: hidden !important;
}
.os-host,
.os-host-textarea {
    position: relative;
    overflow: visible !important;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}
.sidebar {
    height: calc(100% - (3.5rem + 1px));
    overflow-y: auto;
    padding-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0;
}

.os-resize-observer-host.observed {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.os-resize-observer-host {
    padding: inherit;
    border: inherit;
    border-color: transparent;
    border-style: solid;
    box-sizing: border-box;
}
.os-resize-observer,
.os-resize-observer-host {
    box-sizing: inherit;
    display: block;
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    pointer-events: none;
    z-index: -1;
}

@media (min-width: 767.98px) {
    #nav-toggler {
        display: none;
    }
}

@media (max-width: 767.98px) {
    .sidebar-open .main-sidebar,
    .sidebar-open .main-sidebar::before {
        margin-left: 0;
    }

    #nav-toggler {
        display: block;
    }
}

@media (max-width: 991.98px) {
    .sidebar-open #sidebar-overlay {
        display: block;
    }
}
#sidebar-overlay {
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1037;
}

.nav-sidebar .nav-item > .nav-link {
    position: relative;
}
.nav-sidebar .nav-item > .nav-link {
    margin-bottom: 0.2rem;
}
[class*='sidebar-dark-'] .sidebar a {
    color: #c2c7d0;
}
.nav-pills .nav-link {
    color: #6c757d;
}
.nav-pills .nav-link {
    border-radius: 0.25rem;
}
.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.main-sidebar .brand-text,
.main-sidebar .logo-xl,
.main-sidebar .logo-xs,
.sidebar .nav-link p,
.sidebar .user-panel .info {
    transition: margin-left 0.3s linear, opacity 0.3s ease, visibility 0.3s ease;
}
.nav-sidebar .nav-link p {
    display: inline-block;
    margin: 0;
}
.nav-sidebar .nav-item > .nav-link .right {
    transition: -webkit-transform ease-in-out 0.3s;
    transition: transform ease-in-out 0.3s;
    transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}
.nav-sidebar .nav-link > .right,
.nav-sidebar .nav-link > p > .right {
    position: absolute;
    right: 1rem;
    top: 0.7rem;
}
.nav-icon.fa,
.nav-sidebar > .nav-item .nav-icon.fab,
.nav-sidebar > .nav-item .nav-icon.far,
.nav-sidebar > .nav-item .nav-icon.fas,
.nav-sidebar > .nav-item .nav-icon.glyphicon,
.nav-sidebar > .nav-item .nav-icon.ion {
    font-size: 1.1rem;
}
.nav-sidebar > .nav-item .nav-icon {
    margin-left: 0.05rem;
    font-size: 1.2rem;
    margin-right: 0.2rem;
    text-align: center;
    width: 1.6rem;
}
[class*='sidebar-dark'] .user-panel {
    border-bottom: 1px solid #4f5962;
}
.user-panel,
.user-panel .info {
    overflow: hidden;
    white-space: nowrap;
}
.user-panel .image {
    display: inline-block;
    padding-left: 0.8rem;
}

.user-panel img {
    height: 50px;
    width: 50px;
}
.elevation-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
}
.img-circle {
    border-radius: 50%;
}

.navbar-light {
    background-color: #f8f9fa;
}
[class*='sidebar-light-'] {
    background-color: #fff;
}

[class*='sidebar-light'] .brand-link {
    border-bottom: 1px solid #dee2e6;
    color: rgba(0, 0, 0, 0.8);
}
[class*='sidebar-light-'] .sidebar a {
    color: #343a40;
}

.sidebar {
    height: calc(100% - (3.5rem + 1px));
    overflow-y: auto;
    padding-bottom: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0;
}

.increase-width {
    width: 300px;
    margin-left: 0;
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    padding: 15px;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 2.2rem);
    padding-left: var(--bs-gutter-x, 2.2rem);
    margin-right: auto;
    margin-left: auto;
}

@import url('./enhancements.css');
